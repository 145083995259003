import Layout from "../../components/Layout";
import React from "react";
import EducatorCategoryList from "../../components/EducatorCategoryList";
import { Helmet } from "react-helmet";

export default () => {
  const title = "Reflections Category | Educator Resources";
  const description =
    "Research shows that one of the most important aspects of teaching is reflecting on our work as educators. Come here for resources to support your growth in and beyond your work with children.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1 className="title">Reflections</h1>
            <p>{description}</p>
            <EducatorCategoryList category="reflections" />
          </div>
        </div>
      </section>
    </Layout>
  );
};
